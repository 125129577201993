import React, { useState, useEffect, useRef } from "react";
import Left from "../../Left";
import Top from "../../Top";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Button, Grid, Box } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import shopingBag from "../../../assets/images/shpoingBag.svg";
import tabImg1 from "../../../assets/images/tabImg1.svg";
import tabImg2 from "../../../assets/images/tabImg2.svg";
import tabImg3 from "../../../assets/images/tabImg3.svg";
import tabImg4 from "../../../assets/images/tabImg4.svg";
import noDataFond from "../../../assets/images/nodata.jpg";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import moment from "moment";
import printIcon from "../../../assets/images/printIcon.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import loaderImg from "../../../assets/images/logo.png";
import refund from "../../../assets/images/refund2.jpg";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "react-qr-code";
// End

import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Tooltip from "@mui/material/Tooltip";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// New Tabs
function CustomTabPanelNew(props) {
  const { children, valueNewTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueNewTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {valueNewTab === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanelNew.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropsNew(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function Orders() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [searchString, setSearchSting] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("Active");
  const [orderList, setOrderList] = useState([]);
  const [orderMarketList, setOrderMarketList] = useState([]);

  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [starCafeDetails, setStarCafeDetails] = useState(null);
  const [selectedServiceType, setSelectedServiceType] = useState("Starcafe");

  const [newQuantityRefund, setOpenQuantityRefund] = React.useState(false);
  const [selectedQuantity, setSelectedQuantity] = React.useState();
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectedOrderObj, setSelectedOrderObj] = React.useState();

  const handleClickOpenQtyRefund = (quantity, index, cafeDetails) => {
    let isAccess='false';

    console.log(JSON.parse(localStorage.getItem("accessRights")) )
    if(JSON.parse(localStorage.getItem("accessRights")) != null)
      {
        console.log("In If")
        for(var i=0;i<JSON.parse(localStorage.getItem("accessRights")).length;i++)
        {
          console.log("In for")
            if(JSON.parse(localStorage.getItem("accessRights"))[i].moduleName=='Refund' && JSON.parse(localStorage.getItem("accessRights"))[i].status==true)
            {
              console.log("In If Access true")
              isAccess='true'
              break;
            }
        }
      } 

      if(isAccess=='true')
      {
        setOpenQuantityRefund(true);
        setSelectedIndex(index);
        setSelectedOrderObj(cafeDetails);
        setCount(1);
      }
      else{
        swal("Access Denied","Contact to account department for refund item","warning",{ timer: 4000,
          buttons: false,})
      }
    
  };

  const handleCloseQtyRefund = () => {
    setOpenQuantityRefund(false);
  };

  const [count, setCount] = useState(0);
  const [countErrMsg, setCountErrMsg] = useState();

  const incrementCount = () => {
    let cnt = count + 1;
    if (cnt <= JSON.parse(selectedOrderObj.orderdetails[selectedIndex]).count)
      setCount(count + 1);
    else setCountErrMsg("Invalid count");
  };
  const decrementCount = () => {
    let cnt = count - 1;
    if (cnt > 0) setCount(count - 1);
    else setCountErrMsg("Invalid count");
  };

  async function sendNotification(title, message, employee_id) {
    let inputData = {
      title: title,
      message: message,
      resident_id: employee_id,
    };

    await makePostRequest("employee/notificationtoadmin", inputData)
      .then((response) => { })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  //Cafe StarMarket get List Start
  const getStarMarketList = async (searchString, orderType, orderStatus) => {
    setSearchSting(searchString);
    setSelectedOrderStatus(orderStatus);
    setSelectedOrderType(orderType);
    setShowLoader(true);
    await makeGetRequest(
      "starmarket/getorderbytype/" +
      orderType +
      "/" +
      orderStatus +
      "/" +
      searchString
    )
      .then((response) => {
        if (response.data) {
          if (response.data.data[0]) {
            setOrderMarketList(response.data.data);
            starMarketOrderDetails(response.data.data[0].order_id);
          } else {
            orderMarketList.length = 0;
            setOrderMarketList([]);
            setStarCafeDetails(null);
          }
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  //Cafe StarMarket get List end

  const starMarketOrderDetails = async (id) => {
    setSelectedServiceType("Starmarket");
    setSelectedOrderId(id);
    setShowLoader(true);
    await makeGetRequest("starmarket/getorder/" + id)
      .then((response) => {
        if (response.data.data) {
          setShowLoader(true);
          setStarCafeDetails(response.data.data[0]);
          // setPickupStartTime(null);
          // setPickupEndTime(null);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };
  // starMarket Order Details end

  //Cafe StarCafe get List Start
  const getStarCafeList = async (searchString, orderType, orderStatus) => {
    setSearchSting(searchString);
    setSelectedOrderStatus(orderStatus);
    setSelectedOrderType(orderType);
    // setShowLoader(true);

    if (searchString == "") searchString = null;
    await makeGetRequest(
      "starcafe/getorderbytype/" +
      orderType +
      "/" +
      orderStatus +
      "/" +
      searchString
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data[0]) {
            setOrderList(response.data.data);
            GetOrderDetails(response.data.data[0].order_id);
          } else {
            orderList.length = 0;
            setOrderList([]);
            setStarCafeDetails(null);
          }
        }
        // setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // setShowLoader(false);
      });
  };
  //Cafe StarCafe get List end
  // StarCafe Order Details Start
  const [breakfastPickupStartTime, setBreakfastPickupStartTime] =
    React.useState();
  const [breakfastPickupEndTime, setBreakfastPickupEndTime] = React.useState();
  const [lunchPickupStartTime, setLunchPickupStartTime] = React.useState();
  const [lunchPickupEndTime, setLunchPickupEndTime] = React.useState();
  const [dinnerPickupStartTime, setDinnerPickupStartTime] = React.useState();
  const [dinnerPickupEndTime, setDinnerPickupEndTime] = React.useState();

  async function GetCafeTiming() {
    await makeGetRequest("starcafe/getcafetiming/" + null + "/Pickup")
      .then((res) => {
        if (res.data.data) {
          // setShowLoader(true);
          console.log(res.data.data);
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].service_type == "Breakfast") {
              setBreakfastPickupStartTime(res.data.data[i].starttime);
              setBreakfastPickupEndTime(res.data.data[i].endtime);
            } else if (res.data.data[i].service_type == "Lunch") {
              setLunchPickupStartTime(res.data.data[i].starttime);
              setLunchPickupEndTime(res.data.data[i].endtime);
            } else if (res.data.data[i].service_type == "Dinner") {
              setDinnerPickupStartTime(res.data.data[i].starttime);
              setDinnerPickupEndTime(res.data.data[i].endtime);
            }
          }
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  }

  const GetOrderDetails = async (id) => {
    setSelectedServiceType("Starcafe");
    setSelectedOrderId(id);
    // setShowLoader(true);
    await makeGetRequest("starcafe/getorder/" + id)
      .then((response) => {
        if (response.data.data) {
          // setShowLoader(true);
          setStarCafeDetails(response.data.data[0]);
        }
        // setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  // StarCafe Order Details end
  // Status Change ready complete start
  const handleChangeMarketStatus = async (id, status, employeeId, cart) => {
    setShowLoader(true);
    let inputdata = {
      order_id: id,
      orderstatus: status,
    };
    await makePostRequest("starmarket/updateorder", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          // if (status == "Readyforpickup")
          //   sendNotification(
          //     "Order Ready",
          //     "Your order is ready please pickup from counter.",
          //     employeeId
          //   );
          // else if (status == "Completed")
          //   sendNotification(
          //     "Order Completed",
          //     "Thanks for coming. Please visit again.",
          //     employeeId
          //   );
          // makePostRequest("starmarket/updateproductcount", cart)
          //   .then((response) => {
          //     console.log(response.data.message);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          setOpenScan(false);
          getStarMarketList(null, "Starmarket", selectedOrderStatus);
        }
        setShowLoader(false);
        setFocus();
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
        setFocus();
      });
  };

  const handleChangeStatus = async (id, status, employeeId, cart) => {
    console.log(cart);

    let inputdata = {
      order_id: id,
      orderstatus: status,
    };
    setShowLoader(true);
    await makePostRequest("starcafe/updateorder", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          // if (status == "Readyforpickup") {
          //   setOpenScan(false);
          //   // sendNotification("Order Ready", "Your order is ready please pickup the food.",employeeId);
          // } else if (status == "Completed") {

          //   // makePostRequest("starcafe/updatesubcategorycount", cart)
          //   // .then((response) => {

          //   // })
          //   // .catch((err) => {
          //   //   console.log(err);
          //   // });
          //   setOpenScan(false);
          sendNotification(
            "Order Completed",
            "Thanks for coming. Please visit again.",
            employeeId
          );
          // }
          setOpenScan(false);
          getStarCafeList(null, selectedOrderType, selectedOrderStatus);
        }
        setFocus();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
        setFocus();
      });
    // setFocus();
  };

  async function insertOrUpdateRefundHistory(index, cafeDetails) {

    if (parseInt(count) > 0) {
      setShowLoader(true);
      console.log(cafeDetails);
      let refundQnt = 0;
      let updatedInventory = JSON.parse(cafeDetails.orderdetails[index]);
      if (updatedInventory.refundedQty)
        refundQnt = updatedInventory.refundedQty;
      updatedInventory.isrefund = true;
      updatedInventory.refundedQty = count + refundQnt;
      updatedInventory.count =
        parseInt(updatedInventory.count) - parseInt(count);
      cafeDetails.orderdetails[index] = JSON.stringify(updatedInventory);

      let inputData = {
        employee_id: cafeDetails.employee_id,
        order_id: cafeDetails.order_id,
        star_refunded:
          (count * updatedInventory.price) /
          localStorage.getItem("masterdollarvalue"),
        order_type: cafeDetails.ordertype,
      };
      await makePostRequest("employee/insertrefundhist", inputData)
        .then((response) => {
          if (response.data.data !== undefined) {
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err);
        });

      let starInputData = {
        employee_id: cafeDetails.employee_id,
        total_stars:
          parseInt(cafeDetails.total_stars) +
          parseInt(count * updatedInventory.price) /
          parseInt(localStorage.getItem("masterdollarvalue")),
      };
      await makePostRequest("employee/insertemployeestar", starInputData)
        .then((response) => { })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });

      if (updatedInventory.type == "Product") {
        await makeGetRequest(
          "starcafe/updatesubcategoryquantity/" +
          updatedInventory.subcategory_id +
          "/" +
          updatedInventory.count
        )
          .then((response) => {
            console.log("Updated");
          })
          .catch((err) => {
            console.log(err);
            setShowLoader(false);
          });
      }


      let orderData = {
        order_id: cafeDetails.order_id,
        orderdetails: cafeDetails.orderdetails,
      };
      console.log(orderData.orderdetails)
      let newObj = orderData.orderdetails.find((o) => JSON.parse(o).count != 0);

      console.log(newObj)

      if (newObj == undefined)
        orderData.orderstatus = "Refunded";

      await makePostRequest("starcafe/updateorder", orderData)
        .then((response) => {
          console.log("Updated");
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });

      setOpenQuantityRefund(false);

      await GetOrderDetails(starCafeDetails.order_id);

      setShowLoader(false);
    }
  }

  const [timeFormat, setTimeFormat] = React.useState("hh:mm:ss");

  const handleScan = async (orderObj) => {
    document.getElementById("scanText").value = "";

    if (JSON.parse(orderObj).serviceType == "Starcafe")
      await GetOrderDetails(JSON.parse(orderObj).orderId);
    else if (JSON.parse(orderObj).serviceType == "Starmarket")
      await starMarketOrderDetails(JSON.parse(orderObj).orderId);

    setOpenScan(true);
    setScroll("body");
  };

  const handleChange = (newValue, type) => {
    setValue(newValue);
    if (type == null) getStarCafeList(null, null, "Active");
    else if (type == "Starmarket")
      getStarMarketList(null, "Starmarket", "Active");
    setFocus();
  };

  // NewTabs
  const [valueNewTab, setValueNewTab] = React.useState(0);

  const handleChangeNewTab = (event, newValue) => {
    setValueNewTab(newValue);
  };
  // End

  const inputRef = useRef(null);
  const [qrValue, setQrValue] = useState("");
  // const [tempQrVal, setTempQrVal] = useState("");

  useEffect(() => {
    localStorage.setItem("selectedModule", "Orders");
    GetCafeTiming();
    getStarCafeList(searchString, selectedOrderType, selectedOrderStatus);
    // const interval = setInterval(() => {
    //   getStarCafeList(searchString, selectedOrderType, selectedOrderStatus);
    // }, 10000);

    // return () => clearInterval(interval);

    // setTimeout(() => {
    //   setFocus()
    // }, 10000);
  }, []);

  // Open Carton Or Single
  const [OpenScan, setOpenScan] = React.useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpenScan = (scrollType) => () => {
    setOpenScan(true);
    setScroll(scrollType);
  };

  const closeOpenScan = () => {
    setOpenScan(false);
  };
  const descriptionElementRef = useRef(null);
  // End
  const [timer, setTimer] = React.useState();
  const timeoutVal = 10;

  function handleKeyUp(e) {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    setTimer(
      window.setTimeout(() => {
        console.log(e.target.value);
        if (e.target.value != "") handleScan(e.target.value);
      }, timeoutVal)
    );
  }

  function handleKeyPress(e) {
    window.clearTimeout(timer);
    console.log("Typing...");
  }

  async function setFocus() {
    inputRef.current.focus();
  }

  const onClickPrintDetails = (type) => {
    navigate("/ScannedOrder/" + starCafeDetails.order_id, {
      state: { data: type },
    });
  };

  return (
    <>
      <Top />
      <Left />
      <div className="rightContentMain">
        <div className="orderTabMain">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Box sx={{ width: "100%" }} className="sticyTab">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Cafe"
                      {...a11yProps(0)}
                      component={() => (
                        <Button
                          className="tabImg"
                          onClick={() => handleChange(0, null)}
                        >
                          <div className="tabImgInr">
                            <img src={tabImg2} />
                          </div>
                          <p>Cafe</p>
                        </Button>
                      )}
                    />

                    <Tab
                      label="Star Market"
                      {...a11yProps(1)}
                      component={() => (
                        <Button
                          className="tabImg"
                          onClick={() => handleChange(1, "Starmarket")}
                        >
                          <div className="tabImgInr">
                            <img src={tabImg1} />
                          </div>
                          <p>Star Market</p>
                        </Button>
                      )}
                    />

                    <Tab
                      label="Barber Shop"
                      {...a11yProps(2)}
                      component={() => (
                        <Button
                          className="tabImg"
                          onClick={() => handleChange(2)}
                        >
                          <div className="tabImgInr">
                            <img src={tabImg3} />
                          </div>
                          <p>Barber Shop</p>
                        </Button>
                      )}
                    />

                    <Tab
                      label="Sports"
                      {...a11yProps(3)}
                      component={() => (
                        <Button
                          className="tabImg"
                          onClick={() => handleChange(3)}
                        >
                          <div className="tabImgInr">
                            <img src={tabImg4} />
                          </div>
                          <p>Sports</p>
                        </Button>
                      )}
                    />
                  </Tabs>
                </Box>

                <CustomTabPanel
                  className="tabContentMain newTabMain"
                  value={value}
                  index={0}
                >
                  <div className="tabContDiv">
                    <Grid container spacing={0}>
                      <Grid item xs={12} md={12}>
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={valueNewTab}
                              onChange={handleChangeNewTab}
                              variant="scrollable"
                              scrollButtons="auto"
                              aria-label="basic tabs example"
                            >
                              <Tab
                                label="All"
                                {...a11yPropsNew(0)}
                                onClick={() =>
                                  getStarCafeList(null, null, "Active")
                                }
                              />
                              <Tab
                                label="Star Cafe"
                                {...a11yPropsNew(1)}
                                onClick={() =>
                                  getStarCafeList(null, "Starcafe", "Active")
                                }
                              />
                              <Tab
                                label="Breakfast"
                                {...a11yPropsNew(2)}
                                onClick={() =>
                                  getStarCafeList(null, "Breakfast", "Active")
                                }
                              />
                              <Tab
                                label="Lunch"
                                {...a11yPropsNew(3)}
                                onClick={() =>
                                  getStarCafeList(null, "Lunch", "Active")
                                }
                              />
                              <Tab
                                label="Dinner"
                                {...a11yPropsNew(4)}
                                onClick={() =>
                                  getStarCafeList(null, "Dinner", "Active")
                                }
                              />
                            </Tabs>
                          </Box>

                          {/* All Tab */}
                          <CustomTabPanel value={valueNewTab} index={0}>
                            <div className="newTabsContentOter">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Active"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(null, null, "Active")
                                        }
                                      >
                                        {selectedOrderStatus == "Active" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        New Order
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus ==
                                            "Readyforpickup"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "Readyforpickup"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "Readyforpickup" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Waiting For Pickup
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Completed"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "Completed"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Completed" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        <marquee scrollamount="5">
                                          {" "}
                                          Completed / Refunded
                                        </marquee>
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "NotPickedUp"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "NotPickedUp"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "NotPickedUp" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Not Picked
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <div className="prodSearchDiv">
                                        <input
                                          // type="text"
                                          value={searchString}
                                          className="prodSearchInpt"
                                          placeholder="Search"
                                          onChange={(e) =>
                                            getStarCafeList(
                                              e.target.value,
                                              selectedOrderType,
                                              selectedOrderStatus
                                            )
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className="orderListing">
                                    {orderList.map((AllObj) => (
                                      <div
                                        className={
                                          AllObj.ordertype == "Dinner"
                                            ? selectedOrderId == AllObj.order_id
                                              ? "orderDivOuter dinnerOrderColor seectedOrder"
                                              : "orderDivOuter dinnerOrderColor"
                                            : AllObj.ordertype == "Breakfast"
                                              ? selectedOrderId == AllObj.order_id
                                                ? "orderDivOuter breakfastOrderColor seectedOrder"
                                                : "orderDivOuter breakfastOrderColor"
                                              : AllObj.ordertype == "Lunch"
                                                ? selectedOrderId == AllObj.order_id
                                                  ? "orderDivOuter lunchOrderColor seectedOrder"
                                                  : "orderDivOuter lunchOrderColor"
                                                : selectedOrderId == AllObj.order_id
                                                  ? "orderDivOuter seectedOrder"
                                                  : "orderDivOuter"
                                        }
                                        onClick={() =>
                                          GetOrderDetails(AllObj.order_id)
                                        }
                                        key={AllObj.order_id}
                                      >
                                        <div className="orderIcoDiv">
                                          <img src={shopingBag} />
                                        </div>

                                        <div>
                                          <h1>Order # {AllObj.order_id}</h1>
                                          <h2>
                                            By {AllObj.first_name}{" "}
                                            {AllObj.last_name}
                                          </h2>
                                          <p>
                                            {moment(AllObj.created_at).format(
                                              "DD/MM/YYYY hh:mm a"
                                            )}
                                          </p>
                                        </div>

                                        <b className="startCount">
                                          <StarIcon /> {AllObj.totalstarspend}
                                        </b>
                                        {AllObj.orderstatus == 'Refunded' ?
                                          <div className="refuundedTx">
                                            Refunded
                                          </div> : <></>}
                                      </div>
                                    ))}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          {/* StarCafe Tab */}
                          <CustomTabPanel value={valueNewTab} index={1}>
                            <div className="newTabsContentOter">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Active"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Starcafe",
                                            "Active"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Active" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        New Order
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus ==
                                            "Readyforpickup"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Starcafe",
                                            "Readyforpickup"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "Readyforpickup" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Waiting For Pickup
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Completed"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Starcafe",
                                            "Completed"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Completed" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Completed/Refunded
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "NotPickedUp"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Starcafe",
                                            "NotPickedUp"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "NotPickedUp" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Not Picked
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <div className="prodSearchDiv">
                                        <input
                                          type="text"
                                          className="prodSearchInpt"
                                          placeholder="Search"
                                          value={searchString}
                                          onChange={(e) =>
                                            getStarCafeList(
                                              e.target.value,
                                              selectedOrderType,
                                              selectedOrderStatus
                                            )
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {orderList ? (
                                    <div className="orderListing">
                                      {orderList.map((starCafeObj) => (
                                        <div
                                          className={
                                            selectedOrderId ==
                                              starCafeObj.order_id
                                              ? "orderDivOuter seectedOrder"
                                              : "orderDivOuter "
                                          }
                                          onClick={() =>
                                            GetOrderDetails(
                                              starCafeObj.order_id
                                            )
                                          }
                                          key={starCafeObj.order_id}
                                        >
                                          <div className="orderIcoDiv">
                                            <img src={shopingBag} />
                                          </div>
                                          <div>
                                            <h1>
                                              Order # {starCafeObj.order_id}
                                            </h1>
                                            <h2>
                                              By {starCafeObj.first_name}{" "}
                                              {starCafeObj.last_name}
                                            </h2>
                                            <p>
                                              {moment(
                                                starCafeObj.created_at
                                              ).format("DD/MM/YYYY hh:mm a")}
                                            </p>
                                          </div>
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {starCafeObj.totalstarspend}
                                          </b>
                                          {starCafeObj.orderstatus == 'Refunded' ?
                                            <div className="refuundedTx">
                                              Refunded
                                            </div> : <></>}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="noDataFond">
                                      <img src={noDataFond} />
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          {/* BreakFast Tab */}
                          <CustomTabPanel value={valueNewTab} index={2}>
                            <div className="newTabsContentOter">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Active"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Breakfast",
                                            "Active"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Active" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        New Order
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Completed"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Breakfast",
                                            "Completed"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Completed" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Completed
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "NotPickedUp"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "NotPickedUp"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "NotPickedUp" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Not Picked
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <div className="prodSearchDiv">
                                        <input
                                          type="text"
                                          className="prodSearchInpt"
                                          placeholder="Search"
                                          value={searchString}
                                          onChange={(e) =>
                                            getStarCafeList(
                                              e.target.value,
                                              selectedOrderType,
                                              selectedOrderStatus
                                            )
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {orderList ? (
                                    <div className="orderListing">
                                      {orderList.map((breakFastObj) => (
                                        <div
                                          className={
                                            selectedOrderId ==
                                              breakFastObj.order_id
                                              ? "orderDivOuter breakfastOrderColor seectedOrder"
                                              : "orderDivOuter breakfastOrderColor"
                                          }
                                          onClick={() =>
                                            GetOrderDetails(
                                              breakFastObj.order_id
                                            )
                                          }
                                          key={breakFastObj.order_id}
                                        >
                                          <div className="orderIcoDiv">
                                            <img src={shopingBag} />
                                          </div>
                                          <div>
                                            <h1>
                                              Order # {breakFastObj.order_id}
                                            </h1>
                                            <h2>
                                              By {breakFastObj.first_name}{" "}
                                              {breakFastObj.last_name}
                                            </h2>
                                            <p>
                                              {moment(
                                                breakFastObj.created_at
                                              ).format("DD/MM/YYYY hh:mm a")}
                                            </p>
                                          </div>
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {breakFastObj.totalstarspend}
                                          </b>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="noDataFond">
                                      <img src={noDataFond} />
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          {/* Lunch Tab */}
                          <CustomTabPanel value={valueNewTab} index={3}>
                            <div className="newTabsContentOter">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Active"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Lunch",
                                            "Active"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Active" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        New Order
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Completed"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Lunch",
                                            "Completed"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Completed" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Completed
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "NotPickedUp"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "NotPickedUp"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "NotPickedUp" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Not Picked
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <div className="prodSearchDiv">
                                        <input
                                          type="text"
                                          className="prodSearchInpt"
                                          placeholder="Search"
                                          value={searchString}
                                          onChange={(e) =>
                                            getStarCafeList(
                                              e.target.value,
                                              selectedOrderType,
                                              selectedOrderStatus
                                            )
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {orderList ? (
                                    <div className="orderListing">
                                      {orderList.map((lunchObj) => (
                                        <div
                                          className={
                                            selectedOrderId == lunchObj.order_id
                                              ? "orderDivOuter lunchOrderColor seectedOrder"
                                              : "orderDivOuter lunchOrderColor"
                                          }
                                          onClick={() =>
                                            GetOrderDetails(lunchObj.order_id)
                                          }
                                          key={lunchObj.order_id}
                                        >
                                          <div className="orderIcoDiv">
                                            <img src={shopingBag} />
                                          </div>
                                          <div>
                                            <h1>Order # {lunchObj.order_id}</h1>
                                            <h2>
                                              By {lunchObj.first_name}{" "}
                                              {lunchObj.last_name}
                                            </h2>
                                            <p>
                                              {moment(
                                                lunchObj.created_at
                                              ).format("DD/MM/YYYY hh:mm a")}
                                            </p>
                                          </div>
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {lunchObj.totalstarspend}
                                          </b>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="noDataFond">
                                      <img src={noDataFond} />
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>

                          {/* Dinner Tab */}
                          <CustomTabPanel value={valueNewTab} index={4}>
                            <div className="newTabsContentOter">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Active"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Dinner",
                                            "Active"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Active" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        New Order
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "Completed"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            "Dinner",
                                            "Completed"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus == "Completed" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Completed
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                      <Button
                                        className={
                                          selectedOrderStatus == "NotPickedUp"
                                            ? "orderFlterBt seectedOrderType"
                                            : "orderFlterBt"
                                        }
                                        onClick={() =>
                                          getStarCafeList(
                                            null,
                                            null,
                                            "NotPickedUp"
                                          )
                                        }
                                      >
                                        {selectedOrderStatus ==
                                          "NotPickedUp" ? (
                                          <em className="newOrdCout">
                                            {orderList.length}
                                          </em>
                                        ) : (
                                          <></>
                                        )}
                                        Not Picked
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <div className="prodSearchDiv">
                                        <input
                                          type="text"
                                          className="prodSearchInpt"
                                          placeholder="Search"
                                          value={searchString}
                                          onChange={(e) =>
                                            getStarCafeList(
                                              e.target.value,
                                              selectedOrderType,
                                              selectedOrderStatus
                                            )
                                          }
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {orderList ? (
                                    <div className="orderListing">
                                      {orderList.map((dinnerObj) => (
                                        <div
                                          className={
                                            selectedOrderId ==
                                              dinnerObj.order_id
                                              ? "orderDivOuter dinnerOrderColor seectedOrder"
                                              : "orderDivOuter dinnerOrderColor"
                                          }
                                          onClick={() =>
                                            GetOrderDetails(dinnerObj.order_id)
                                          }
                                          key={dinnerObj.order_id}
                                        >
                                          <div className="orderIcoDiv">
                                            <img src={shopingBag} />
                                          </div>
                                          <div>
                                            <h1>
                                              Order # {dinnerObj.order_id}
                                            </h1>
                                            <h2>
                                              By {dinnerObj.first_name}{" "}
                                              {dinnerObj.last_name}
                                            </h2>
                                            <p>
                                              {moment(
                                                dinnerObj.created_at
                                              ).format("DD/MM/YYYY hh:mm a")}
                                            </p>
                                          </div>
                                          <b className="startCount">
                                            <StarIcon />{" "}
                                            {dinnerObj.totalstarspend}
                                          </b>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <div className="noDataFond">
                                      <img src={noDataFond} />
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </CustomTabPanel>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel
                  className="tabContentMain"
                  value={value}
                  index={1}
                >
                  <div className="tabContDiv">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={3}>
                            <Button
                              className={
                                selectedOrderStatus == "Active"
                                  ? "orderFlterBt seectedOrderType"
                                  : "orderFlterBt"
                              }
                              onClick={() =>
                                getStarMarketList(null, "Starmarket", "Active")
                              }
                            >
                              {selectedOrderStatus == "Active" ? (
                                <em className="newOrdCout">
                                  {orderMarketList.length}
                                </em>
                              ) : (
                                <></>
                              )}
                              New Order
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              className={
                                selectedOrderStatus == "Readyforpickup"
                                  ? "orderFlterBt seectedOrderType"
                                  : "orderFlterBt"
                              }
                              onClick={() =>
                                getStarMarketList(
                                  null,
                                  "Starmarket",
                                  "Readyforpickup"
                                )
                              }
                            >
                              {selectedOrderStatus == "Readyforpickup" ? (
                                <em className="newOrdCout">
                                  {orderMarketList.length}
                                </em>
                              ) : (
                                <></>
                              )}
                              Waiting For Pickup
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              className={
                                selectedOrderStatus == "Completed"
                                  ? "orderFlterBt seectedOrderType"
                                  : "orderFlterBt"
                              }
                              onClick={() =>
                                getStarMarketList(
                                  null,
                                  "Starmarket",
                                  "Completed"
                                )
                              }
                            >
                              {selectedOrderStatus == "Completed" ? (
                                <em className="newOrdCout">
                                  {orderMarketList.length}
                                </em>
                              ) : (
                                <></>
                              )}
                              Completed
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Button
                              className={
                                selectedOrderStatus == "NotPickedUp"
                                  ? "orderFlterBt seectedOrderType"
                                  : "orderFlterBt"
                              }
                              onClick={() =>
                                getStarMarketList(
                                  null,
                                  "Starmarket",
                                  "NotPickedUp"
                                )
                              }
                            >
                              {selectedOrderStatus == "NotPickedUp" ? (
                                <em className="newOrdCout">
                                  {orderMarketList.length}
                                </em>
                              ) : (
                                <></>
                              )}
                              Not Picked
                            </Button>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <div className="prodSearchDiv">
                              <input
                                type="text"
                                value={searchString}
                                className="prodSearchInpt"
                                placeholder="Search"
                                onChange={(e) =>
                                  getStarMarketList(
                                    e.target.value,
                                    selectedOrderType,
                                    selectedOrderStatus
                                  )
                                }
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <div className="orderListing">
                          {orderMarketList.map((marketListObj, index) => (
                            <div
                              className={
                                selectedOrderId == marketListObj.order_id
                                  ? "orderDivOuter seectedOrder"
                                  : "orderDivOuter "
                              }
                              onClick={() =>
                                starMarketOrderDetails(marketListObj.order_id)
                              }
                              key={index}
                            >
                              <div className="orderIcoDiv">
                                <img src={shopingBag} />
                              </div>
                              <div>
                                <h1>Order # {marketListObj.order_id}</h1>
                                <h2>
                                  By {marketListObj.first_name}{" "}
                                  {marketListObj.last_name}
                                </h2>
                                <p>
                                  {moment(marketListObj.created_at).format(
                                    "DD/MM/YYYY hh:mm a"
                                  )}
                                </p>
                              </div>
                              <b className="startCount">
                                <StarIcon /> {marketListObj.totalstarspend}
                              </b>
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel
                  className="tabContentMain"
                  value={value}
                  index={2}
                >
                  <div className="tabContDiv">
                    <div className="noDataFond">
                      <img src={noDataFond} />
                    </div>
                  </div>
                </CustomTabPanel>

                <CustomTabPanel
                  className="tabContentMain"
                  value={value}
                  index={3}
                >
                  <div className="tabContDiv">
                    <div className="noDataFond">
                      <img src={noDataFond} />
                    </div>
                  </div>
                </CustomTabPanel>
              </Box>
            </Grid>

            <Grid item xs={12} md={7}>
              {starCafeDetails ? (
                <div className="newOrderView">
                  <div
                    className="openScanOuter"
                    style={{ textAlign: "center", position: "relative" }}
                  >
                    <input
                      type="text"
                      id="scanText"
                      ref={inputRef}
                      // value={qrValue}
                      className="qrscantextbox"
                      autoFocus
                      onKeyUp={handleKeyUp}
                      onChange={handleKeyPress}
                    // onKeyUp={handleKeyUp}
                    // on
                    />
                    {/* <input  type="text" id="scanText" value={scanTextVal} autoFocus/> */}
                  </div>
                  {/* <div ref={ref}>
                  </div> */}
                  <div className="orderDetailsSection bodyScanNew">
                    {starCafeDetails.orderstatus == "NotPickedUp" ? (
                      <div className="notPickedTx">
                        <div>
                          <ErrorOutlineIcon /> This Order is not Picked
                        </div>
                        <CloseIcon />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="orderTop orderNewTp">
                      <h1>
                        Order #{starCafeDetails.order_id}{" "}
                        <span style={{ marginLeft: 20, color: "#c82d33" }}>
                          By {starCafeDetails.first_name}{" "}
                          {starCafeDetails.last_name}
                        </span>
                      </h1>
                      <p className="nameOfCust">
                        {/* By {starCafeDetails.first_name}{" "}
                        {starCafeDetails.last_name} */}
                      </p>
                      <div className="pulsOut">
                        <Tooltip title="Scan QRCode" arrow placement="top">
                          <Button
                            className="pulsingBt"
                            // onClick={() => handleScan(1225)}
                            onClick={setFocus}
                          >
                            <QrCodeScannerIcon />
                          </Button>
                        </Tooltip>
                        <img
                          src={printIcon}
                          onClick={() =>
                            onClickPrintDetails(starCafeDetails.ordertype)
                          }
                          className="printNow"
                        />
                      </div>
                    </div>
                    <div className="orderSumry">
                      <div>
                        <label>Order Date & Time</label>
                        <p>
                          {moment(starCafeDetails.created_at).format(
                            "DD/MM/YYYY hh:mm a"
                          )}
                          {/* &nbsp;&nbsp;{" "}
                          <span style={{ color: "#c82d33" }}>12:30 pm</span> */}
                        </p>
                      </div>
                      <div>
                        <label className="pickpSlot">Pickup Slot</label>
                        {/* <span className="picSlotLabel">Dinner Time</span> */}
                        <p className="pickpSlotTime">
                          {starCafeDetails.ordertype == "Breakfast" ? (
                            <>
                              {breakfastPickupStartTime ? (
                                <>
                                  {moment(
                                    breakfastPickupStartTime,
                                    "HH:mm"
                                  ).format("h:mm A")}{" "}
                                  to{" "}
                                  {moment(
                                    breakfastPickupEndTime,
                                    "HH:mm"
                                  ).format("h:mm A")}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </>
                          ) : (
                            <>
                              {starCafeDetails.ordertype == "Lunch" ? (
                                <>
                                  {lunchPickupStartTime ? (
                                    <>
                                      {moment(
                                        lunchPickupStartTime,
                                        "HH:mm"
                                      ).format("h:mm A")}{" "}
                                      to{" "}
                                      {moment(
                                        lunchPickupEndTime,
                                        "HH:mm"
                                      ).format("h:mm A")}
                                    </>
                                  ) : (
                                    <>-</>
                                  )}
                                </>
                              ) : (
                                <>
                                  {starCafeDetails.ordertype == "Dinner" ? (
                                    <>
                                      {dinnerPickupStartTime ? (
                                        <>
                                          {moment(
                                            dinnerPickupStartTime,
                                            "HH:mm"
                                          ).format("h:mm A")}{" "}
                                          to{" "}
                                          {moment(
                                            dinnerPickupEndTime,
                                            "HH:mm"
                                          ).format("h:mm A")}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <div>
                        <label>Pickup Location</label>
                        {starCafeDetails.ordertype == "Starmarket" ? (
                          <p>Star Market</p>
                        ) : (
                          <p>Star Cafe</p>
                        )}
                      </div>
                    </div>
                    {/* //Changed requested by Tanishq for direct status changes to completed removed waiting for pickup */}
                    {/* {starCafeDetails.ordertype == "Starmarket" ? (
                      <div className="readyForPicOut">
                        {starCafeDetails.orderstatus == "Active" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeMarketStatus(
                                starCafeDetails.order_id,
                                "Readyforpickup",
                                starCafeDetails.employee_id
                              )
                            }
                          >
                            Waiting For Pickup
                          </Button>
                        ) : starCafeDetails.orderstatus == "Readyforpickup" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeMarketStatus(
                                starCafeDetails.order_id,
                                "Completed",
                                starCafeDetails.employee_id,
                                starCafeDetails.orderdetails
                              )
                            }
                          >
                            Mark As Completed
                          </Button>
                        ) : starCafeDetails.orderstatus == "Completed" ? (
                          <Button className="readyForPicBt completedMarkBt">
                            Completed
                          </Button>
                        ) : null}
                      </div>
                    ) : starCafeDetails.ordertype == "Starcafe" ? (
                      <div className="readyForPicOut">
                        {starCafeDetails.orderstatus == "Active" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeStatus(
                                starCafeDetails.order_id,
                                "Readyforpickup",
                                starCafeDetails.employee_id
                              )
                            }
                          >
                            Waiting For Pickup
                          </Button>
                        ) : starCafeDetails.orderstatus == "Readyforpickup" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeStatus(
                                starCafeDetails.order_id,
                                "Completed",
                                starCafeDetails.employee_id,
                                starCafeDetails.orderdetails,
                                starCafeDetails.orderdetails
                              )
                            }
                          >
                            Mark As Completed
                          </Button>
                        ) : starCafeDetails.orderstatus == "Completed" ? (
                          <Button className="readyForPicBt completedMarkBt">
                            Completed
                          </Button>
                        ) : starCafeDetails.orderstatus == "NotPickedUp" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeStatus(
                                starCafeDetails.order_id,
                                "Completed",
                                starCafeDetails.employee_id,
                                starCafeDetails.orderdetails,
                                starCafeDetails.orderdetails
                              )
                            }
                          >
                            Mark As Completed
                          </Button>
                        ) : null}
                      </div>
                    ) : (
                      <div className="readyForPicOut">
                        {starCafeDetails.orderstatus == "Active" ||
                        starCafeDetails.orderstatus == "NotPickedUp" ? (
                          <Button
                            className="readyForPicBt"
                            onClick={() =>
                              handleChangeStatus(
                                starCafeDetails.order_id,
                                "Completed",
                                starCafeDetails.employee_id,
                                starCafeDetails.orderdetails
                              )
                            }
                          >
                            Mark As Completed
                          </Button>
                        ) : starCafeDetails.orderstatus == "Completed" ? (
                          <Button className="readyForPicBt completedMarkBt">
                            Completed
                          </Button>
                        ) : null}
                      </div>
                    )} */}
                    {JSON.parse(localStorage.getItem("accessRights") != null) ? (
                      <>
                        {JSON.parse(localStorage.getItem("accessRights")).map(
                          (module) => (
                            <>
                              {module.moduleName == "OrderStatusChange" &&
                                module.status == true ? (
                                <>
                                  {starCafeDetails.ordertype == "Starmarket" ? (
                                    <div className="readyForPicOut">
                                      {starCafeDetails.orderstatus == "Active" ? (
                                        //   <Button
                                        //     className="readyForPicBt"
                                        //     onClick={() =>
                                        //       handleChangeMarketStatus(
                                        //         starCafeDetails.order_id,
                                        //         "Readyforpickup",
                                        //         starCafeDetails.employee_id
                                        //       )
                                        //     }
                                        //   >
                                        //     Waiting For Pickup
                                        //   </Button>
                                        // ) : starCafeDetails.orderstatus == "Readyforpickup" ? (
                                        <Button
                                          className="readyForPicBt"
                                          onClick={() =>
                                            handleChangeMarketStatus(
                                              starCafeDetails.order_id,
                                              "Completed",
                                              starCafeDetails.employee_id,
                                              starCafeDetails.orderdetails
                                            )
                                          }
                                        >
                                          Mark As Completed
                                        </Button>
                                      ) : starCafeDetails.orderstatus == "Completed" ? (
                                        <Button className="readyForPicBt completedMarkBt">
                                          Completed
                                        </Button>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="readyForPicOut">
                                      {starCafeDetails.orderstatus == "Active" ||
                                        starCafeDetails.orderstatus == "NotPickedUp" ||
                                        starCafeDetails.orderstatus == "Readyforpickup" ? (
                                        <Button
                                          className="readyForPicBt"
                                          onClick={() =>
                                            handleChangeStatus(
                                              starCafeDetails.order_id,
                                              "Completed",
                                              starCafeDetails.employee_id,
                                              starCafeDetails.orderdetails
                                            )
                                          }
                                        >
                                          Mark As Completed
                                        </Button>
                                      ) : starCafeDetails.orderstatus == "Completed" ? (
                                        <Button className="readyForPicBt completedMarkBt">
                                          Completed
                                        </Button>
                                      ) : null}
                                    </div>
                                  )}
                                </>

                              ) : (
                                <></>
                              )}
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <div
                      className="productDetails"
                      style={{ marginBottom: 15 }}
                    >
                      <table width="100%">
                        <thead>
                          <tr>
                            <th>Product ID</th>
                            <th>Product Name</th>
                            <th>Weight</th>
                            <th>Quantity</th>
                            <th>Stars Spent</th>
                            {starCafeDetails.ordertype == "Starcafe" && starCafeDetails.orderstatus != "NotPickedUp" ? (
                              <th>Refund</th>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {starCafeDetails.orderdetails ? (
                            <>
                              {starCafeDetails.orderdetails.map(
                                (data, index) => (
                                  <tr key={index}>
                                    {
                                      starCafeDetails.ordertype == 'Starmarket' ?
                                        <td>
                                          #
                                          {JSON.parse(data).product_id}{" "}
                                        </td> :
                                        <td>
                                          #
                                          {JSON.parse(data).menu_id
                                            ? JSON.parse(data).menu_id
                                            : JSON.parse(data)
                                              .subcategory_id}{" "}
                                        </td>
                                    }

                                    {
                                      <td>
                                        {JSON.parse(data).menu_name
                                          ? JSON.parse(data).menu_name
                                          : JSON.parse(data).product_name}
                                      </td>
                                    }
                                    {JSON.parse(data).type == "product" ? (
                                      <td>
                                        {JSON.parse(data).weight}{" "}
                                        {JSON.parse(data).weight_unit}
                                      </td>
                                    ) : (
                                      <td>----</td>
                                    )}
                                    <td>{JSON.parse(data).count}</td>
                                    {JSON.parse(data).price?
                                    <td className="starSpen">
                                        {(JSON.parse(data).count *
                                          JSON.parse(data).price) /
                                          localStorage.getItem(
                                            "masterdollarvalue"
                                          )}
                                    </td>:
                                    <td className="starSpen">
                                      0
                                </td>}
                                    
                                    {(starCafeDetails.ordertype == "Starcafe" && starCafeDetails.orderstatus != "NotPickedUp")? (
                                      <td className="refundedTx">
                                        {(JSON.parse(data).refundedQty > 0 && JSON.parse(data).count != 0) ? (
                                          <h1>
                                            <b>
                                              {JSON.parse(data).refundedQty}
                                            </b>{" "}
                                            Refunded{" "}
                                          </h1>
                                        ) : (
                                          <></>
                                        )}
                                        {JSON.parse(data).isrefund == true &&
                                          JSON.parse(data).count == 0 ? (
                                          <h1>Refunded</h1>
                                        ) : (
                                                        <img
                                                          src={refund}
                                                          className="refundIC"
                                                          onClick={() =>
                                                            handleClickOpenQtyRefund(
                                                              JSON.parse(data).count,
                                                              index,
                                                              starCafeDetails
                                                            )
                                                          }
                                                        />
                                                      
                                                  
                                        )}
                                      </td>
                                    ) : (
                                      <></>
                                    )}
                                  </tr>
                                )
                              )}
                            </>
                          ) : (
                            <>No Order Details </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="qrOuterMain">
                      <div className="qrOter">
                        <div className="qrDiv">
                          <div
                            style={{
                              height: "auto",
                              margin: "0 auto",
                              width: "100%",
                            }}
                          >
                            <QRCode
                              size={256}
                              style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                              }}
                              value={JSON.stringify({
                                orderId: starCafeDetails.order_id,
                                serviceType: selectedServiceType,
                              })}
                              // value={JSON.stringify(tempJsonObj)}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="orderItemDetails">
                        <h1>Order Details</h1>
                        <div className="orderItemDiv">
                          <span>
                            <p>Order Id</p>
                            <b>#{starCafeDetails.order_id}</b>
                          </span>
                          <span>
                            <p>Total Items</p>
                            {starCafeDetails.orderdetails ? (
                              <b>{starCafeDetails.orderdetails.length}</b>
                            ) : (
                              <b>0</b>
                            )}
                          </span>
                          <span>
                            <p>Stars Spent</p>
                            <b className="totalAmt">
                              {starCafeDetails.totalstarspend}
                            </b>
                          </span>
                          <span>
                            <p>Stars Refunded</p>
                            <b className="totalAmt">
                              {starCafeDetails.total_refund == null
                                ? 0
                                : parseInt(starCafeDetails.total_refund)}
                            </b>
                          </span>
                          <span>
                            <p>Total Star Spent</p>
                            <b className="totalAmt">
                              {starCafeDetails.total_refund != null ? (
                                <>
                                  {parseInt(starCafeDetails.totalstarspend) -
                                    parseInt(starCafeDetails.total_refund)}
                                </>
                              ) : (
                                parseInt(starCafeDetails.totalstarspend)
                              )}
                            </b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="noDataFond">
                  <img src={noDataFond} />
                </div>
              )}

              {/* <div ref={scannerContainerRef} >
                <QrReader
                  constraints={{ facingMode: 'environment' }}
                  onResult={(event) => handleScan(event)}
                  onError={setError}
                />
              </div> */}
              {/* <div>
                <QrScanner onScan={(data)=>console.log(data)}/>
              </div> */}
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Open Scan */}
      <div>
        <Dialog
          open={OpenScan}
          onClose={closeOpenScan}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          {starCafeDetails ? (
            <>
              {starCafeDetails.orderstatus == "Completed" ? (
                <DialogTitle
                  id="scroll-dialog-title"
                  className="completedOrdSt"
                >
                  Order Already Completed
                  <CloseIcon className="modalCloseBt" onClick={closeOpenScan} />
                </DialogTitle>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {starCafeDetails ? (
            <>
              {starCafeDetails.ordertype == "Starmarket" ||starCafeDetails.ordertype == "Starcafe"||
                moment().isBetween(
                  moment(
                    starCafeDetails.ordertype == "Breakfast"
                      ? breakfastPickupStartTime
                      : starCafeDetails.ordertype == "Lunch"
                        ? lunchPickupStartTime
                        : dinnerPickupStartTime,
                    timeFormat
                  ),
                  moment(
                    starCafeDetails.ordertype == "Breakfast"
                      ? breakfastPickupEndTime
                      : starCafeDetails.ordertype == "Lunch"
                        ? lunchPickupEndTime
                        : dinnerPickupEndTime,
                    timeFormat
                  )
                  ) ||
                starCafeDetails.orderstatus == "Completed" ? (
                <>
                  <DialogContent dividers={scroll === "paper"}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      {starCafeDetails ? (
                        <div className="newOrderView">
                          <div className="modalSinlInputDiv">
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <div className="scanDivMain" id="printablediv">
                                  <div className="scanOrderMain">
                                    <div className="scanOrderContDiv">
                                      <div className="orderDetailsSection">
                                        <div className="orderTop orderNewTp">
                                          <h1>
                                            Order #{starCafeDetails.order_id}
                                          </h1>
                                          <p className="nameOfCust">
                                            By {starCafeDetails.first_name}{" "}
                                            {starCafeDetails.last_name}
                                          </p>
                                          <img
                                            src={printIcon}
                                            className="printNow"
                                            onClick={() =>
                                              navigate(
                                                "/ScannedOrder/" +
                                                starCafeDetails.order_id
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="orderSumry">
                                          <div>
                                            <label>Order Date & Time</label>
                                            {/* {/ <p>Today, 02:57pm</p> /} */}
                                            <p>
                                              {moment(
                                                starCafeDetails.created_at
                                              ).format("DD/MM/YYYY hh:mm a")}
                                            </p>
                                          </div>
                                          <div>
                                            <label className="pickpSlot">
                                              Pickup Slot
                                            </label>
                                            <p className="pickpSlotTime">
                                              {starCafeDetails.ordertype ==
                                                "Breakfast" ? (
                                                <>
                                                  {breakfastPickupStartTime ? (
                                                    <>
                                                      {moment(
                                                        breakfastPickupStartTime,
                                                        "HH:mm"
                                                      ).format("h:mm A")}{" "}
                                                      to{" "}
                                                      {moment(
                                                        breakfastPickupEndTime,
                                                        "HH:mm"
                                                      ).format("h:mm A")}
                                                    </>
                                                  ) : (
                                                    <>-</>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {starCafeDetails.ordertype ==
                                                    "Lunch" ? (
                                                    <>
                                                      {lunchPickupStartTime ? (
                                                        <>
                                                          {moment(
                                                            lunchPickupStartTime,
                                                            "HH:mm"
                                                          ).format(
                                                            "h:mm A"
                                                          )}{" "}
                                                          to{" "}
                                                          {moment(
                                                            lunchPickupEndTime,
                                                            "HH:mm"
                                                          ).format("h:mm A")}
                                                        </>
                                                      ) : (
                                                        <>-</>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {starCafeDetails.ordertype ==
                                                        "Dinner" ? (
                                                        <>
                                                          {dinnerPickupStartTime ? (
                                                            <>
                                                              {moment(
                                                                dinnerPickupStartTime,
                                                                "HH:mm"
                                                              ).format(
                                                                "h:mm A"
                                                              )}{" "}
                                                              to{" "}
                                                              {moment(
                                                                dinnerPickupEndTime,
                                                                "HH:mm"
                                                              ).format(
                                                                "h:mm A"
                                                              )}
                                                            </>
                                                          ) : (
                                                            <>-</>
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                          <div>
                                            <label>Order Type</label>
                                            <p>{starCafeDetails.ordertype}</p>
                                          </div>
                                          <div>
                                            <label>Pickup Location</label>
                                            {starCafeDetails.ordertype ==
                                              "Starmarket" ? (
                                              <p>Star Market</p>
                                            ) : (
                                              <p>Star Cafe</p>
                                            )}
                                          </div>
                                        </div>

                                        <div className="">
                                          {starCafeDetails.ordertype ==
                                            "Starmarket" ? (
                                            <div className="readyForPicOut">
                                              {starCafeDetails.orderstatus ==
                                                "Active" ||starCafeDetails.orderstatus ==
                                                "NotPickedUp"? (
                                                <Button
                                                  className="readyForPicBt"
                                                  onClick={() =>
                                                    handleChangeMarketStatus(
                                                      starCafeDetails.order_id,
                                                      "Completed",
                                                      starCafeDetails.employee_id,
                                                      starCafeDetails.orderdetails
                                                    )
                                                  }
                                                >
                                                  Mark As Completed
                                                </Button>
                                              ) : starCafeDetails.orderstatus ==
                                                "Completed" ? (
                                                <Button className="readyForPicBt completedMarkBt">
                                                  Completed
                                                </Button>
                                              ) : null}
                                            </div>
                                          ) : starCafeDetails.ordertype ==
                                            "Starcafe" ? (
                                            <div className="readyForPicOut">
                                              {starCafeDetails.orderstatus ==
                                                "Active"||starCafeDetails.orderstatus ==
                                                "NotPickedUp" ? (
                                                <Button
                                                  className="readyForPicBt"
                                                  onClick={() =>
                                                    handleChangeStatus(
                                                      starCafeDetails.order_id,
                                                      "Completed",
                                                      starCafeDetails.employee_id,
                                                      starCafeDetails.orderdetails
                                                    )
                                                  }
                                                >
                                                  Mark As Completed
                                                </Button>
                                              ) : starCafeDetails.orderstatus ==
                                                "Completed" ? (
                                                <Button className="readyForPicBt completedMarkBt">
                                                  Completed
                                                </Button>
                                              ) : null}
                                            </div>
                                          ) : (
                                            <div className="readyForPicOut">
                                              {starCafeDetails.orderstatus ==
                                                "Active" ? (
                                                <Button
                                                  className="readyForPicBt"
                                                  onClick={() =>
                                                    handleChangeStatus(
                                                      starCafeDetails.order_id,
                                                      "Completed",
                                                      starCafeDetails.employee_id,
                                                      starCafeDetails.orderdetails
                                                    )
                                                  }
                                                >
                                                  Mark As Completed
                                                </Button>
                                              ) : starCafeDetails.orderstatus ==
                                                "Completed" ? (
                                                <Button
                                                  className="readyForPicBt completedMarkBt"
                                                // onClick={() =>
                                                //   handleChangeStatus(
                                                //     starCafeDetails.order_id,
                                                //     "Active"
                                                //   )
                                                // }
                                                >
                                                  Completed
                                                </Button>
                                              ) : null}
                                            </div>
                                          )}
                                        </div>

                                        <div
                                          className="productDetails"
                                          style={{ marginBottom: 15 }}
                                        >
                                          <table width="100%">
                                            <thead>
                                              <tr>
                                                <th>Product ID</th>
                                                <th>Product Name</th>
                                                <th>Weight</th>
                                                <th>Quantity</th>
                                                <th>Stars Spent</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {starCafeDetails.orderdetails ? (
                                                <>
                                                  {starCafeDetails.orderdetails.map(
                                                    (data, index) => (
                                                      <tr key={index}>
                                                        {
                                                          <td>
                                                            #
                                                            {JSON.parse(data)
                                                              .menu_id
                                                              ? JSON.parse(data)
                                                                .menu_id
                                                              : JSON.parse(data)
                                                                .subcategory_id}{" "}
                                                          </td>
                                                        }

                                                        {
                                                          <td>
                                                            {JSON.parse(data)
                                                              .menu_name
                                                              ? JSON.parse(data)
                                                                .menu_name
                                                              : JSON.parse(data)
                                                                .product_name}
                                                          </td>
                                                        }
                                                        {JSON.parse(data)
                                                          .type == "product" ? (
                                                          <td>
                                                            {
                                                              JSON.parse(data)
                                                                .weight
                                                            }{" "}
                                                            {
                                                              JSON.parse(data)
                                                                .weight_unit
                                                            }
                                                          </td>
                                                        ) : (
                                                          <td>----</td>
                                                        )}
                                                        <td>
                                                          {
                                                            JSON.parse(data)
                                                              .count
                                                          }
                                                        </td>
                                                        {
                                                          <td className="starSpen">
                                                            {JSON.parse(data)
                                                              .Star_price
                                                              ? JSON.parse(data)
                                                                .Star_price
                                                              : JSON.parse(data)
                                                                .price_in_stars}
                                                          </td>
                                                        }
                                                      </tr>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <>No Order Details</>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="qrOuterMain">
                                          <div className="qrOter">
                                            <div className="qrDiv">
                                              {/* <Zoom>
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                                      <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={JSON.stringify({ orderid: starCafeDetails.order_id, orderStatus: starCafeDetails.orderstatus, ordertype: starCafeDetails.ordertype, orderDetails: cafeDetailsTableData })}
                                        viewBox={`0 0 256 256`}
                                      />
                                    </div></Zoom> */}
                                              <div
                                                style={{
                                                  height: "auto",
                                                  margin: "0 auto",
                                                  width: "100%",
                                                }}
                                              >
                                                <QRCode
                                                  size={256}
                                                  style={{
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                  }}
                                                  value={{ value: "Hi Snehal" }}
                                                  viewBox={`0 0 256 256`}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="orderItemDetails">
                                            <h1>Order Details</h1>
                                            <div className="orderItemDiv">
                                              <span>
                                                <p>Order Id</p>
                                                <b>
                                                  #{starCafeDetails.order_id}
                                                </b>
                                              </span>
                                              <span>
                                                <p>Total Items</p>
                                                <b>
                                                  {
                                                    starCafeDetails.orderdetails
                                                      .length
                                                  }
                                                </b>
                                              </span>
                                              <span>
                                                <p>Stars Spent</p>
                                                <b className="totalAmt">
                                                  {
                                                    starCafeDetails.totalstarspend
                                                  }
                                                </b>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      ) : null}
                    </DialogContentText>
                  </DialogContent>
                </>
              ) : (
                <DialogContent>
                  <div className="wrongTimeScanOrder">
                    <span>Please scan correct order</span>
                  </div>
                </DialogContent>
              )}
            </>
          ) : (
            <></>
          )}
        </Dialog>
      </div>

      {/* Refund product (Quantity) */}
      <div>
        <Dialog
          open={newQuantityRefund}
          // onClose={closeCartonOrSingle}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="modalMain bigModal"
        >
          <DialogTitle id="scroll-dialog-title">
            Refund an Item
            {/* <CloseIcon className="modalCloseBt qtyModalClose" onClick={handleCloseQtyRefund} /> */}
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div className="forModalSpace">
                <div className="modalSinlInputDiv">
                  <div className="QtyRefundTopContent">
                    <h3>Do you really want to refund for this item?</h3>
                    <p>This prodess can not be undone</p>
                  </div>
                  <div className="outerIncdec">
                    <Button className="IncDrcBtn" onClick={decrementCount}>
                      <RemoveIcon />
                    </Button>

                    <b className="countValue countValue2">{count}</b>

                    <Button className="IncDrcBtn" onClick={incrementCount}>
                      <AddIcon />
                    </Button>
                  </div>

                  <Grid item xs={12} md={12} className="lineBtns">
                    <Button
                      className="cancelEditBtn"
                      onClick={handleCloseQtyRefund}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modalBtSmallWidth"
                      onClick={() =>
                        insertOrUpdateRefundHistory(
                          selectedIndex,
                          selectedOrderObj
                        )
                      }
                    >
                      Refund
                    </Button>
                  </Grid>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />
            {/* <svg
              class="cart"
              role="img"
              aria-label="Shopping cart line animation"
              viewBox="0 0 128 128"
              width="128px"
              height="128px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="8"
              >
                <g class="cart__track" stroke="hsla(0,10%,10%,0.1)">
                  <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
                  <circle cx="43" cy="111" r="13" />
                  <circle cx="102" cy="111" r="13" />
                </g>
                <g class="cart__lines" stroke="currentColor">
                  <polyline
                    class="cart__top"
                    points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                    stroke-dasharray="338 338"
                    stroke-dashoffset="-338"
                  />
                  <g class="cart__wheel1" transform="rotate(-90,43,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="43"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                  <g class="cart__wheel2" transform="rotate(90,102,111)">
                    <circle
                      class="cart__wheel-stroke"
                      cx="102"
                      cy="111"
                      r="13"
                      stroke-dasharray="81.68 81.68"
                      stroke-dashoffset="81.68"
                    />
                  </g>
                </g>
              </g>
            </svg> */}
            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Orders;
