import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DemoReport = () => {
  const data = {
    labels: ["2024-11-16", "2024-11-17", "2024-11-18", "2024-11-19", "2024-11-20"], // x-axis labels
    datasets: [
      {
        label: "Dataset 1",
        data: [10, 20, 15, 25, 30], // y-axis data
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.4, // Smooth line
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart Example with Dates",
      },
    },
    scales: {
      x: {
        type: "time", // Use a time scale for dates
        time: {
          unit: "day", // Adjust the unit as needed (e.g., 'day', 'month')
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default DemoReport;