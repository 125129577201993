import React from 'react';
import { LineChart } from "@mui/x-charts/LineChart";

// const data = [
//     { month: 'Jan', value1: 100, value2: 50 },
//     { month: 'Feb', value1: 120, value2: 60 },
//     { month: 'Mar', value1: 150, value2: 70 },
//     { month: 'Apr', value1: 180, value2: 80 },
//     { month: 'May', value1: 200, value2: 90 },
// ];


const data = [
    { x: 1, y: 100 },
    { x: 2, y: 120 },
    { x: 3, y: 150 },
    { x: 4, y: 180 },
    { x: 5, y: 200 },
];




const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
  'Page A',
  'Page B',
  'Page C',
  'Page D',
  'Page E',
  'Page F',
  'Page G',
];

function LineChartExample() {
    return (
        // <div>Hi</div>
        <>{JSON.stringify(data)}
            <LineChart
                width={500}
                height={300}
                series={[
                    { data: pData, label: 'pv' },
                    { data: uData, label: 'uv' },
                ]}
                xAxis={[{ scaleType: 'point', data: xLabels }]}
            />
        </>
    );
}

export default LineChartExample;