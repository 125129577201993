import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { PieChart } from "@mui/x-charts/PieChart";
// import { LineChart } from "@mui/x-charts/LineChart";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer
} from "recharts";
// import {
//   LineChart,
//   Line,

// } from '@mui/x-charts';

import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

// import Tooltip from "@mui/material/Tooltip";
import deletIco from "../../../assets/images/delete-icon.svg";
import eye from "../../../assets/images/eye.svg";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import loaderImg from "../../../assets/images/logo.png";


import AOS from "aos";
import "aos/dist/aos.css";
import {
  makeGetRequest,
  makePostRequest,
} from "../../../utils/utils";
import moment from "moment";
import { ElectricalServicesSharp } from "@mui/icons-material";
// Pie Graph
const data = [
  { id: 0, value: 20, label: "Completed" },
  { id: 1, value: 20, label: "Ready For Pickup" },
  { id: 2, value: 60, label: "New" },
];
// End

function ReportOrderTab() {


  const [orderType, setOrderType] = React.useState(null);
  // const[serviceType,setServiceType]=React.useState(null);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);

  const [upload, setUpload] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);

  const [departmentList, setDepartmentList] = React.useState([]);
  const [ordersList, setOrdersList] = React.useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = React.useState(0);
  const [totalCompletedStars, setTotalCompletedStars] = React.useState(0);
  const [totalActiveStars, setTotalActiveStars] = React.useState(0);
  const [totalRefundedStars, setTotalRefundedStars] = React.useState(0);
  const [totalNotPickedStars, setTotalNotPickedStars] = React.useState(0);
  const [selectedDepartmentId, setSelectedDepartmentId] = React.useState(null);
  const [selectedDepartmentName, setSelectedDepartmentName] = React.useState(null);
  const [filteredCafeOrdersList, setFilteredCafeOrdersList] = React.useState([]);
  const [filteredMarketOrdersList, setFilteredMarketOrdersList] = React.useState([]);
  const [pieChartData, setPieChartData] = React.useState([]);
  const [LinearChartData, setLinearChartData] = React.useState([{ "department_id": "", "department_name": "", "orderres": [] }]);
  const [orderDeptList, setOrderDeptList] = React.useState([]);
  const [orderDateList, setOrderDateList] = React.useState([]);
  const [orderCountList, setOrderCountList] = React.useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = React.useState("Daily");

 
  async function handleChangeTimePeriod(type) {
    setSelectedTimePeriod(type);
    setOrdersList([]);
    setUpload(!upload)

    if (type == 'Weekly') {
      handleChangeWeekly(fromDate, toDate, type);
    }
    else {
      getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, orderType, null, type);
      getLinearChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus, type);
      getPieChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus)

    }

  }

  async function handleChangeFromDate(newDate) {
    console.log(newDate)
    setFromDate(newDate)
    getOrderReportCount(newDate, toDate, selectedDepartmentId, orderStatus, orderType, null, selectedTimePeriod)
    getPieChartData(newDate, toDate, selectedDepartmentId, orderType, orderStatus);
    getLinearChartData(newDate, toDate, selectedDepartmentId,  orderType, orderStatus, selectedTimePeriod)

    // getOrderReport(newDate,toDate,selectedDepartmentId,orderType,orderStatus);
  }

  async function handleChangeToDate(date) {
    setToDate(date)
    getOrderReportCount(fromDate, date, selectedDepartmentId, orderStatus, orderType, null, selectedTimePeriod)
    getPieChartData(fromDate, date, selectedDepartmentId, orderType);
    getLinearChartData(fromDate, date, selectedDepartmentId,  orderType, orderStatus, selectedTimePeriod)

  }

  async function handleChangeDepartment(e) {
    let deptName = "";
    if (e.target.value == "") {
      setSelectedDepartmentId(null);
      deptName = null;
    }
    else {
      deptName = e.target.value;
      setSelectedDepartmentId(e.target.value);
      let idx = e.target.selectedIndex;
      let dataset = e.target.options[idx].dataset;
      setSelectedDepartmentName(dataset.isd);
    }
    getOrderReportCount(fromDate, toDate, deptName, orderStatus, orderType, null, selectedTimePeriod)
    getLinearChartData(fromDate, toDate, deptName,  orderType, orderStatus, selectedTimePeriod)
    getPieChartData(fromDate, toDate, deptName, orderType, orderStatus)

    // getOrderReport(fromDate,toDate,e.target.value,orderType,orderStatus);
  }

  async function handleChangeService(e) {
    setOrderType(e.target.value);
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, e.target.value, null, selectedTimePeriod)
    getLinearChartData(fromDate, toDate, selectedDepartmentId,  e.target.value, orderStatus, selectedTimePeriod)
    getPieChartData(fromDate, toDate, selectedDepartmentId, e.target.value, orderStatus)

    // getOrderReport(fromDate,toDate,selectedDepartmentId,e.target.value,orderStatus);
  }

  async function handleChangeOrderStatus(e) {
    setOrderStatus(e.target.value);
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, e.target.value, orderType, null, selectedTimePeriod)
    getLinearChartData(fromDate, toDate, selectedDepartmentId,  orderType, e.target.value, selectedTimePeriod)
    getPieChartData(fromDate, toDate, selectedDepartmentId, orderType,e.target.value)

    // getOrderReport(fromDate,toDate,selectedDepartmentId,orderType,e.target.value);
  }

  async function handleChangeSearch(searchString) {
    if (searchString == '' || searchString == undefined)
      searchString = null
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, orderType, searchString, selectedTimePeriod)
  }

  const getAllDepartmentList = async () => {
    setShowLoader(true);

    await makeGetRequest("/employee/getdepartment/null")
      .then((response) => {
        if (response.data.data) {
          setDepartmentList(response.data.data);
          // departmentDropDown.length = 0;
          // for (var i = 0; i < response.data.data.length; i++) {
          //   departmentDropDown.push(response.data.data[i].department_name);
          // }
          // setDepartmentDropDown(departmentDropDown);
        }

        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  async function getOrderReport(fromDate, toDate, department, ordertype, orderstatus) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getorderreport/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          setOrdersList(response.data.data.orderData);
          setTotalOrdersCount(response.data.data.orderData.length)
          // setTotalRevenueInStars(parseInt(response.data.data.totalStarSpendCafe) + parseInt(response.data.data.totalStarSpendMarket))
         
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function getPieChartData(fromDate, toDate, department, ordertype, orderstatus) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getpiechartdata/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          setPieChartData(response.data.data.orderData)
          // { id: 0, value: totalRevenueInStars, label: 'series A' }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }
  const [processedData, setProcessedData] = React.useState([]);
  async function getLinearChartData(fromDate, toDate, department, ordertype, orderstatus, timeperiod) {
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setOrdersList([]);
    setShowLoader(true);
    await makeGetRequest("starcafe/getlinearchartdata/" + ordertype + "/" + orderstatus + "/" + fromDate + "/" + toDate + "/" + department + "/" + timeperiod)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);

          setLinearChartData(response.data.data.orderData)
          let responseArr = response.data.data.orderData;
          // const processData=null;
          if(selectedTimePeriod=="Daily")
          {
          const processData = (data) =>
            data.map((item) => ({
              date: new Date(item.orderdate).toISOString().split("T")[0], // Format date as YYYY-MM-DD
              department_name: item.department_name,
              activcnt: item.activeordercnt?item.activeordercnt[0]?.activcnt || 0:0,
              completedcnt: item.completedordercnt?item.completedordercnt[0]?.completedcnt || 0:0,
              refundedcnt: item.refundedordercnt?item.refundedordercnt[0]?.refundedcnt || 0:0,
              notpickedcnt: item.notpickedupordercnt?item.notpickedupordercnt[0]?.notpickedcnt || 0:0,
            }));
            console.log(processData(responseArr))
            setProcessedData(processData(responseArr));
          }
          else{
           const processData = (data) =>
             data.map((item) => ({
              date: item.temp_name, // Format date as YYYY-MM-DD
              department_name: item.department_name,
              activcnt: item.activeordercnt[0]?.activcnt || 0,
              completedcnt: item.completedordercnt[0]?.completedcnt || 0,
              refundedcnt: item.refundedordercnt[0]?.refundedcnt || 0,
              notpickedcnt: item.notpickedupordercnt[0]?.notpickedcnt || 0,
            }));
            console.log(processData(responseArr))
            setProcessedData(processData(responseArr));
          }
          //   console.log(processData(responseArr))
          // setProcessedData(processData(responseArr));



          let orderResArray = response.data.data.dateArr;

          let result = "";

          if (selectedTimePeriod == 'Daily') {
            // result = orderResArray.map(({ orderdate }) => moment(orderdate).format('YYYY-MM-DD'));
            result = orderResArray.map(({ orderdate }) => moment(orderdate));
          }
          else {
            result = orderResArray.map(({ temp_name }) => temp_name);

          }

          setOrderDateList(result)


        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }


  async function getOrderReportCount(fromDate, toDate, department, orderstatus, ordertype, searchString, timeperiod) {
    console.log(fromDate + " " + toDate)
    if (fromDate == null)
      fromDate = moment().format('YYYY-MM-DD');

    if (toDate == null)
      toDate = moment().format('YYYY-MM-DD');
    setFilteredCafeOrdersList([]);
    setFilteredMarketOrdersList([]);
    setTotalActiveStars(0);
    setTotalCompletedStars(0);
    setTotalNotPickedStars(0);
    setTotalRefundedStars(0);
    setShowLoader(true);
    await makeGetRequest("starcafe/getorderreportcount/" + fromDate + "/" + toDate + "/" + department + "/" + orderstatus + "/" + ordertype + "/" + searchString + "/" + timeperiod)
      .then((response) => {
        if (response.data.data) {
          console.log("Order Data");
          console.log(response.data.data);
          let totOrders=0;
          let totOrderSum=0
        
          if(ordertype=='null'||ordertype==null||ordertype=='Starcafe')
          {
            setFilteredCafeOrdersList(response.data.data.orderData)
          for(var i=0;i<response.data.data.orderData.length;i++)
          {
            totOrders=totOrders+parseInt(response.data.data.orderData[i].count);
            if(response.data.data.orderData[i].count)
            setTotalActiveStars(parseInt(totalActiveStars)+parseInt(response.data.data.orderData[i].activesum));
            setTotalCompletedStars(parseInt(totalCompletedStars)+parseInt(response.data.data.orderData[i].completedsum));
            setTotalNotPickedStars(parseInt(totalNotPickedStars)+parseInt(response.data.data.orderData[i].notpickedsum) )
            setTotalRefundedStars(parseInt(totalRefundedStars)+parseInt(response.data.data.orderData[i].refundedsum) )
          }
          }

          if(ordertype=='null'||ordertype==null||ordertype=='Starmarket')
            {
              setFilteredMarketOrdersList(response.data.data.orderData2)
            for(var i=0;i<response.data.data.orderData2.length;i++)
            {
              totOrders=totOrders+parseInt(response.data.data.orderData2[i].count);
              if(response.data.data.orderData2[i].count)
              setTotalActiveStars(parseInt(totalActiveStars)+parseInt(response.data.data.orderData2[i].activesum));
              setTotalCompletedStars(parseInt(totalCompletedStars)+parseInt(response.data.data.orderData2[i].completedsum));
              setTotalNotPickedStars(parseInt(totalNotPickedStars)+parseInt(response.data.data.orderData2[i].notpickedsum) )
              setTotalRefundedStars(parseInt(totalRefundedStars)+parseInt(response.data.data.orderData2[i].refundedsum) )
            }
            }

          setTotalOrdersCount(totOrders)
          //+parseInt(response.data.data.totalStarSpendMarket)
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        // let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  async function handleChangeWeekly(fromdate, todate, timeperiod) {
    if (fromdate == null)
      fromdate = moment().format('YYYY-MM-DD')
    if (todate == null)
      todate = moment().format('YYYY-MM-DD')
    console.log(fromdate + "   " + todate)

    let startDate = moment(fromdate).startOf('week').format('YYYY-MM-DD');
    let endDate = moment(todate).endOf('week').format('YYYY-MM-DD');
    console.log(startDate + "   " + endDate)
    getOrderReportCount(startDate, endDate, selectedDepartmentId, orderStatus, orderType, null, timeperiod);
    getPieChartData(startDate, endDate, selectedDepartmentId, orderType, orderStatus)
    getLinearChartData(startDate, endDate, selectedDepartmentId, orderType, orderStatus, selectedTimePeriod)

  }

  useEffect(() => {
    AOS.init();
    getAllDepartmentList();
    getOrderReportCount(fromDate, toDate, selectedDepartmentId, orderStatus, orderType, null, selectedTimePeriod)
    getPieChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus)
    getLinearChartData(fromDate, toDate, selectedDepartmentId, orderType, orderStatus, selectedTimePeriod)

  }, []);

  return (
    <>
      <div className="starCafeTabInner">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div className="repoFilterMain">
              <Button
                className={selectedTimePeriod == 'Hourly' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={() => handleChangeTimePeriod('Hourly')}
              >
                Hourly
              </Button>
              <Button
                className={selectedTimePeriod == 'Daily' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={(e) => handleChangeTimePeriod('Daily')}
              >
                Daily
              </Button>
              <Button
                className={selectedTimePeriod == 'WeekDay' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={() => handleChangeTimePeriod('WeekDay')}
              >
                Week Day
              </Button>
              <Button
                className={selectedTimePeriod == 'Weekly' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={() => handleChangeTimePeriod('Weekly')}
              >
                Weekly
              </Button>
              <Button
                className={selectedTimePeriod == 'Monthly' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={() => handleChangeTimePeriod('Monthly')}
              >
                Monthly
              </Button>
              <Button
                className={selectedTimePeriod == 'Yearly' ? "repoFilterBt activeRepoFilterBt" : "repoFilterBt"}
                // className={"repoFilterBt"}
                onClick={() => handleChangeTimePeriod('Yearly')}
              >
                Yearly
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="selectDateRangeMain">
              <div className="selectDateRangeInr">
                <div>
                  <label>Select Date Range</label>
                </div>
                <div className="dateRangReport">
                  <div>
                    <input type="Date" className="prodSearchInpt" onChange={(e) => handleChangeFromDate(e.target.value)} />
                  </div>
                  <b>To</b>
                  <div>
                    <input type="Date" className="prodSearchInpt" onChange={(e) => handleChangeToDate(e.target.value)} />
                  </div>
                </div>
                <div>
                  <Button className="repoFilterBt updateDataB">
                    Update Data
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
      
          <Grid item xs={12} md={12}>
            <h1 className="repoSubHeading">Additional Filters</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="additionalFiltterMain">
              <select className="prodSearchInpt" onChange={(e) => handleChangeDepartment(e)}
                value={selectedDepartmentId} >

                <option value="null">All Department</option>
                {departmentList.map((departmentObj) => (
                  <option key={departmentObj.department_id}
                    value={departmentObj.department_id}
                    data-isd={departmentObj.department_name}>{departmentObj.department_name}</option>
                ))}
              </select>

              <select className="prodSearchInpt" onChange={(e) => handleChangeService(e)}
                value={orderType}>
                <option value="null">All Service</option>
                <option value="Starmarket">Star Market</option>
                <option value="Starcafe">Star Cafe</option>
                <option value="Barbershop">Barber Shop</option>
                <option value="sport">Sport</option>
              </select>

              <select className="prodSearchInpt" onChange={(e) => handleChangeOrderStatus(e)}
                value={orderStatus}>
                <option value="null">All Order Status</option>
                <option value="Active">New</option>
                <option value="Completed">Completed</option>
                <option value="NotPickedUp">NotPickedUp</option>
                <option value="Refunded">Refunded</option>
              </select>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportChartsMain">
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <div className="repoChartContDiv">
                    <div
                      className="repoChartCont chartRepoBlack"
                      data-aos="fade-down"
                      data-aos-delay="300"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>{totalOrdersCount}</b>
                      <p>Total Orders</p>
                    </div>
                    <div
                      className="repoChartCont chartRepoGreen"
                      data-aos="fade-down"
                      data-aos-delay="500"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>
                        <StarIcon /> {orderStatus=='Refunded'?totalRefundedStars:orderStatus=='NotPickedUp'?totalNotPickedStars:orderStatus=='Completed'?totalCompletedStars:orderStatus=='Active'?totalActiveStars:parseInt(totalCompletedStars)+parseInt(totalActiveStars)} 
                      </b>
                      <p>{orderStatus=='Refunded'?<>Refunded Orders Amount In Stars</>:orderStatus=='NotPickedUp'?<>NotPicked Orders Amount In Stars</>: orderStatus=='Completed'?<>Completed Orders Amount In Stars</>:orderStatus=='Active'?<>Active Orders Amount In Stars</>:<>Revenue In Stars</>}  </p>
                    </div>
                    <div
                      className="repoChartCont chartRepoBlue"
                      data-aos="fade-down"
                      data-aos-delay="700"
                      data-aos-anchor-placement="center-bottom"
                    >
                      <b>Rs.{orderStatus=='Refunded'?parseInt(totalRefundedStars)*parseInt(localStorage.getItem('masterdollarvalue')):orderStatus=='NotPickedUp'?parseInt(totalNotPickedStars)*parseInt(localStorage.getItem('masterdollarvalue')):orderStatus=='Active'?parseInt(totalActiveStars)*parseInt(localStorage.getItem('masterdollarvalue')):orderStatus=='Completed'?parseInt(totalCompletedStars)*parseInt(localStorage.getItem('masterdollarvalue')):(parseInt(totalActiveStars)+parseInt(totalCompletedStars))*parseInt(localStorage.getItem('masterdollarvalue'))}</b>
                      {/* <b>Rs.{parseInt(totalRevenueInStars) * parseInt(localStorage.getItem('masterdollarvalue'))}</b> */}
                      <p>Revenue In Rs</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div
                    className="repoOrdersGraphMain"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">Orders Graph</h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="repoGraIndec">
                          <span className="activeRepoOrder">
                            <TaskAltIcon /> All
                          </span>
                          <span>
                            <TaskAltIcon /> Star Market
                          </span>
                          <span>
                            <TaskAltIcon /> Star Café
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="orderPieGr">
                         
                          <PieChart
                            series={[
                              {
                                data: pieChartData,
                              },
                            ]}
                            width={400}
                            height={200}
                          />

                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {pieChartData.map((option) => (
                          <div className="repoOrderGrpDetai blackDetai">
                            <em></em>
                            <p>{option.value}</p>
                            <span>{option.label}</span>
                          </div>
                        ))}
                        {/* <div className="repoOrderGrpDetai yellowDetai">
                          <em></em>
                          <p>20</p>
                          <span>Ready For Pickup</span>
                        </div>
                        <div className="repoOrderGrpDetai greenDetai">
                          <em></em>
                          <p>20</p>
                          <span>Completed</span>
                        </div> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div
                    className="departChartMain"
                    data-aos="fade-down"
                    data-aos-delay="300"
                    data-aos-anchor-placement="center-bottom"
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <h1 className="orderGrapHeading">
                          Orders By All Departments
                        </h1>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div className="lineRepoChart">
                         
                          {/* <ResponsiveContainer width={7000} height={350}>
                          <LineChart 
                            data={LinearChartData} // Provide the data array
                            margin={{ top: 20, right: 20, left: 20, bottom: 30 }}
                          >
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

                            <XAxis dataKey={selectedTimePeriod=='Daily'?"orderdate":"temp_name"} ticks={orderDateList}  angle={-45} interval={0}  textAnchor="end" 
                            tickFormatter={selectedTimePeriod=='Daily'? (tick) => tick.split("-").slice(1).join("-"):(tick) => tick.slice(0,3)} 
                                                          
                              />

                            <YAxis scale="linear" domain={[0, 'auto']} />

                            <Tooltip />
                            {LinearChartData.map((department, index) => (

                              <Line key={index} type="monotone" dataKey="ordercnt"
                                data={department.orderres} stroke="#8884d8"  />
                            ))}
                           

                          </LineChart>
                          </ResponsiveContainer> */}



                          <LineChart
                            width={900}
                            height={400}
                            data={processedData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                          >
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip
        content={({ payload, label }) => {
          if (payload && payload.length) {
            const data = payload[0].payload;
            return (
              <div className="custom-tooltip">
                {selectedTimePeriod=="Daily"?
                <p>{`Date: ${label}`}</p>:selectedTimePeriod=="Hourly"?<p>{`Hour: ${label}`}</p>:selectedTimePeriod=="Weekly"?<p>{`Week: ${label}`}</p>:<p>{`: ${label}`}</p>}
                <p>{selectedDepartmentId?`Department: ${data.department_name}`:`All Department`}</p>
                <p>{`Active Orders: ${data.activcnt}`}</p>
                <p>{`Completed Orders: ${data.completedcnt}`}</p>
                <p>{`Refunded Orders: ${data.refundedcnt}`}</p>
                <p>{`Not Picked Orders: ${data.notpickedcnt}`}</p>
              </div>
            );
          }
          return null;
        }}
      />
                            <Legend />

                            {/* Lines for each count */}
                            <Line
                              type="monotone"
                              dataKey="activcnt"
                              stroke="#8884d8"
                              name="Active Orders"
                            />
                            <Line
                              type="monotone"
                              dataKey="completedcnt"
                              stroke="#82ca9d"
                              name="Completed Orders"
                            />
                            <Line
                              type="monotone"
                              dataKey="refundedcnt"
                              stroke="#ff7300"
                              name="Refunded Orders"
                            />
                            <Line
                              type="monotone"
                              dataKey="notpickedcnt"
                              stroke="#ff0000"
                              name="Not Picked Orders"
                            />
                          </LineChart>

                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={12}>
            <div className="reportSearchMain">
              <input
                type="text"
                className="prodSearchInpt"
                placeholder="Search"
                onChange={(e) => handleChangeSearch(e.target.value)}
              />
              <div className="hoverForDrop">
                <Button className="repoBtn">
                  <InsertPageBreakIcon /> Export Report
                </Button>
                <div className="btDropHover">
                  <p>Export as .xslx</p>
                  <p>Export as .doc</p>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="reportPageTblScrollOt">
            <div
              className="productDetails groceryTableMain reportPageTblScroll"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Date
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Order Count
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Service Type
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Department
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Status
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Revenue (Rs)
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Revenue in (*)
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Total Item Sold
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Avg Order Price
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Details
                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {orderType=='null'||orderType==null||orderType=='Starcafe'?
                  <>
                  {filteredCafeOrdersList.map((filteredOrderObj) => (
                    <tr>
                      {selectedTimePeriod == 'Daily' ?
                        <td>{moment(filteredOrderObj.orderdate).format('YYYY-MM-DD')}</td> :
                        selectedTimePeriod == 'Weekly' ?
                          <td>Week {filteredOrderObj.temp_name} <p>{moment().year(2024).week(filteredOrderObj.temp_name).startOf('week').format('YYYY-MM-DD')} to {moment().year(2024).week(filteredOrderObj.temp_name).endOf('week').format('YYYY-MM-DD')}</p></td> :
                          <td>{filteredOrderObj.temp_name}</td>}
                      <td> {filteredOrderObj.count}</td>
                      <td>Star Cafe</td>
                      <td>{selectedDepartmentName?selectedDepartmentName:<>All</>}</td>
                      <td>{orderStatus?orderStatus:<>All</>}</td>
                      <td>{orderStatus=='Active'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.activesum:orderStatus=='Completed'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.completedsum:orderStatus=='Refunded'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.refundedsum:orderStatus=='NotPickedUp'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.notpickedsum:parseInt(localStorage.getItem('masterdollarvalue')) *( parseInt(filteredOrderObj.notpickedsum)+parseInt(filteredOrderObj.activesum)+parseInt(filteredOrderObj.completedsum)+parseInt(filteredOrderObj.refundedsum))}</td>
                      <td>{orderStatus=='Active'?filteredOrderObj.activesum:orderStatus=='Completed'?filteredOrderObj.completedsum:orderStatus=='Refunded'?filteredOrderObj.refundedsum:orderStatus=='NotPickedUp'? filteredOrderObj.notpickedsum: parseInt(filteredOrderObj.notpickedsum)+parseInt(filteredOrderObj.activesum)+parseInt(filteredOrderObj.completedsum)+parseInt(filteredOrderObj.refundedsum)}</td>
                      <td>-</td>
                      <td>-</td>
                      <td onClick={() => selectedTimePeriod == 'Daily' ? getOrderReport(moment(filteredOrderObj.orderdate).format('YYYY-MM-DD'), moment(filteredOrderObj.orderdate).format('YYYY-MM-DD'), selectedDepartmentId, 'Starcafe', orderStatus) :
                        selectedTimePeriod == 'Weekly' ? getOrderReport(moment().year(2024).week(filteredOrderObj.temp_name).startOf('week').format('YYYY-MM-DD'), moment().year(2024).week(filteredOrderObj.temp_name).endOf('week').format('YYYY-MM-DD'), selectedDepartmentId, 'Starcafe', orderStatus) :
                          selectedTimePeriod == 'Monthly' ? getOrderReport(moment().year(2024).month(filteredOrderObj.temp_name).startOf('month').format('YYYY-MM-DD'), moment().year(2024).month(filteredOrderObj.temp_name).endOf('month').format('YYYY-MM-DD'), selectedDepartmentId, 'Starcafe', orderStatus) :
                            selectedTimePeriod == 'Yearly' ? getOrderReport(moment().year(2024).year(filteredOrderObj.temp_name).startOf('year').format('YYYY-MM-DD'), moment().year(2024).year(filteredOrderObj.temp_name).endOf('year').format('YYYY-MM-DD'), selectedDepartmentId, 'Starcafe', orderStatus) : <></>
                      }>Show Details</td>
                    </tr>
                  ))}
                  </>:<></>}
                  {orderType=='null'||orderType==null||orderType=='Starmarket'?
                    <>
                    {filteredMarketOrdersList.map((filteredOrderObj) => (
                      <tr>
                        {selectedTimePeriod == 'Daily' ?
                          <td>{moment(filteredOrderObj.orderdate).format('YYYY-MM-DD')}</td> :
                          selectedTimePeriod == 'Weekly' ?
                            <td>Week {filteredOrderObj.temp_name} <p>{moment().year(2024).week(filteredOrderObj.temp_name).startOf('week').format('YYYY-MM-DD')} to {moment().year(2024).week(filteredOrderObj.temp_name).endOf('week').format('YYYY-MM-DD')}</p></td> :
                            <td>{filteredOrderObj.temp_name}</td>}
                        <td> {filteredOrderObj.count}</td>
                        <td>Star Market</td>
                        <td>{selectedDepartmentName?selectedDepartmentName:<>All</>}</td>
                        <td>{orderStatus?orderStatus:<>All</>}</td>
                        <td>{orderStatus=='Active'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.activesum:orderStatus=='Completed'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.completedsum:orderStatus=='Refunded'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.refundedsum:orderStatus=='NotPickedUp'?parseInt(localStorage.getItem('masterdollarvalue')) * filteredOrderObj.notpickedsum:parseInt(localStorage.getItem('masterdollarvalue')) *( parseInt(filteredOrderObj.notpickedsum)+parseInt(filteredOrderObj.activesum)+parseInt(filteredOrderObj.completedsum)+parseInt(filteredOrderObj.refundedsum))}</td>
                        <td>{orderStatus=='Active'?filteredOrderObj.activesum:orderStatus=='Completed'?filteredOrderObj.completedsum:orderStatus=='Refunded'?filteredOrderObj.refundedsum:orderStatus=='NotPickedUp'? filteredOrderObj.notpickedsum: parseInt(filteredOrderObj.notpickedsum)+parseInt(filteredOrderObj.activesum)+parseInt(filteredOrderObj.completedsum)+parseInt(filteredOrderObj.refundedsum)}</td>
                        <td>-</td>
                        <td>-</td>
                        <td onClick={() => selectedTimePeriod == 'Daily' ? getOrderReport(moment(filteredOrderObj.orderdate).format('YYYY-MM-DD'), moment(filteredOrderObj.orderdate).format('YYYY-MM-DD'), selectedDepartmentId, 'Starmarket', orderStatus) :
                          selectedTimePeriod == 'Weekly' ? getOrderReport(moment().year(2024).week(filteredOrderObj.temp_name).startOf('week').format('YYYY-MM-DD'), moment().year(2024).week(filteredOrderObj.temp_name).endOf('week').format('YYYY-MM-DD'), selectedDepartmentId, 'Starmarket', orderStatus) :
                            selectedTimePeriod == 'Monthly' ? getOrderReport(moment().year(2024).month(filteredOrderObj.temp_name).startOf('month').format('YYYY-MM-DD'), moment().year(2024).month(filteredOrderObj.temp_name).endOf('month').format('YYYY-MM-DD'), selectedDepartmentId, 'Starmarket', orderStatus) :
                              selectedTimePeriod == 'Yearly' ? getOrderReport(moment().year(2024).year(filteredOrderObj.temp_name).startOf('year').format('YYYY-MM-DD'), moment().year(2024).year(filteredOrderObj.temp_name).endOf('year').format('YYYY-MM-DD'), selectedDepartmentId, 'Starmarket', orderStatus) : <></>
                        }>Show Details</td>
                      </tr>
                    ))}
                    </>:<></>}
                </tbody>
              </table>
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="reportPageTblScrollOt">
            <div
              className="productDetails groceryTableMain reportPageTblScroll"
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-anchor-placement="bottom"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Date
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Order ID
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Ordered By
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Service
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Sub Type
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Items
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Status
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Star Price
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                    <th>
                      <div className="smalShortingOuter finaShort">
                        Actions
                        <ToggleButton value="check" className="smalSho">
                          <ImportExportIcon
                            className="smalShorting"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersList.map((orderObj) => (
                    <tr>
                      {orderObj.ordertype == "Starmarket" ?
                        <td>{moment(orderObj.created_at).format('DD/MM/YYYY')}</td> :
                        <td>{moment(orderObj.orderdate).format('DD/MM/YYYY')}</td>}
                      <td># {orderObj.order_id}</td>
                      <td>{orderObj.first_name} {orderObj.last_name}</td>
                      <td>{orderObj.ordertype == "Starmarket" ? <>Star Market</> : <>Star Cafe</>}</td>
                      <td>{orderObj.ordertype}</td>
                      <td>
                        <b style={{ color: "#c82d33" }}>{orderObj.orderdetails.length}</b>
                      </td>
                      <td className={orderObj.orderstatus == 'Completed' ? 'forCompletedS' : orderObj.orderstatus == 'Refunded' ? 'forReadyFP' : orderObj.orderstatus == 'NotPickedUp' ? 'NotPickedUpColor' : ""} >{orderObj.orderstatus == 'Active' ? <>New</> : orderObj.orderstatus}</td>
                      <td>
                        <div className="withStar">
                          <StarIcon /> {orderObj.totalstarspend}
                        </div>
                      </td>
                      <td>
                        <div className="actionBtnOut">
                          <Tooltip title="View" arrow placement="top">
                            <IconButton>
                              <img src={eye} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </Grid>

          {/* <Grid item xs={12} md={12} className="paginationOuterMain">
            <Pagination count={10} variant="outlined" shape="rounded" />
          </Grid> */}
        </Grid>
      </div>


      {showLoader ? (
        <div className="newLoaderMain">
          <div class="preloader">
            <img src={loaderImg} style={{ width: 75 }} />

            <div class="preloader__text">
              <p class="preloader__msg preloader__msg--last">Loading...</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default ReportOrderTab;
